/*
 * @Author: sqk
 * @Date: 2020-08-07 16:16:59
 * @LastEditTime: 2024-05-09 08:58:56
 * @LastEditors: HIDESK\yanbenrong.ex ybr15650585591@163.com
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\utils\api.js
 */
import http from "@/utils/request";

//产品详情数据查询接口
export function showProductInfo(code,orgId) {
  return http({
    method: "get",
    url: "/product/showProductInfo/"+ code + "/"+ orgId +".nd",
  });
}

//产品详情数据查询接口 (未登录)
export function showProductDesc(code) {
  return http({
    method: "get",
    url: "/product/showProductDesc/"+ code +".nd?loginType=CS",
  });
}

//首页 产品详情数据查询接口 (登录) 参数productId
export function product(data) {
  return http({
    method: "get",
    url: "/product/product.nd",
    params: data
  });
}

export function findVideos(id) {
  console.log('findVideos', id)
  return http({
    method: "get",
    url: "/product/findVideos.api?productId="+id,
  });
}

