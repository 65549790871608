import { mapState } from "vuex";
// 导入api接口
import {
  getPrices, //获取产品价格接口
  getStocks, //获取产品库存接口
  getChannelPrice, //获取DMS产品价格接口
  getChannelStock, //获取DMS产品库存接口
  // addToCart, //加入购物车
  addOftenProduct, //加入收藏
  deleteOftenProduct //取消收藏
} from "@/utils/api.js";
import { ctsApi } from "@/views/channel/api";
import { showProductInfo, showProductDesc, product, findVideos } from "./api.js"; // 导入api接口
import { defaultImgPath } from "@/common/constant.js";
import { getChannelStockLIST } from "@/views/product/api";
import { getCust } from "@/views/cart/api";
// import util from "./components/util";
export default {
  name: "ProductDetail",
  data() {
    return {
      dmsKC:0,
      defaultImgPath:defaultImgPath,
      chooseNum: 1,
      productInfo: "", //该单个商品相关信息
      detailInfo: "", //商品全部信息
      filedMap: "", //规格参数
      priceObj: "", //二次获取的价格对象
      stockObj: "", //二次获取的库存对象
      afterPrice: 0, //计算时的单价
      often: "", //点击收藏状态 Y-》已收藏
      // 请求详情所用入参 路由跳转时获取
      productId: "",
      productCode: "",
      orgId: "",

      policyItem: "", //已选择的政策
      //面包屑
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/product",
          name: "product",
          title: "商品橱窗"
        },
        {
          path: "/product/detail",
          name: "productDetail",
          title: "商品详情"
        }
      ],
      zcList: [], //政策数据列表
      zcValue: "", //政策单选传值
      zcCls: "", //政策显示隐藏class
      isZCView: false, //政策是否展开
      navActiveIdx: 1, //活动的叶签 产品介绍1 规格参数2
      // parameterData: [], //产品参数属性数据
      photoList: [], //放大镜缩略图数据
      isFixed: false,
      pageLoadFlag: false,
      targetPage:'',//权限
      showInfo:false,// 下单按钮展示标识
      videoUrl:'',// 视频链接
    };
  },
  watch: {
    $route: function() {
      //首页跳转
      this.productId = this.$route.query.productId
        ? this.$route.query.productId
        : "";
      //其他页面跳转
      this.productCode = this.$route.query.productCode
        ? this.$route.query.productCode
        : "";
      this.orgId = this.$route.query.orgId ? this.$route.query.orgId : "";
      if (this.productId || this.productCode || this.orgId) {
        //判断登陆状态

        //登陆状态
        if (this.userInfo) {
          if (this.productId) {
            //判断首页 首页传productId
            this.getProduct();
          } else {
            this.getShowProductInfo();
          }
        } else {
          //未登录
          this.getshowProductDesc();
        }
      }
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
        ? this.$store.state.user.userInfo
        : localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : "";
    },
    ...mapState({
      productImgBaseUrl: state =>
        state.user.productImgBaseUrl
          ? state.user.productImgBaseUrl
          : localStorage.getItem("productImgBaseUrl")
    })
  },
  beforeCreate() {},
  mounted() {
    // //设置不跳转登录页标志
    // this.$store.commit("user/SET_ISGOLOGIN", false);
    // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    window.addEventListener("scroll", this.handleScroll);
    //首页跳转
    this.productId = this.$route.query.productId
      ? this.$route.query.productId
      : "";
    //其他页面跳转
    this.productCode = this.$route.query.productCode
      ? this.$route.query.productCode
      : "";
    this.orgId = this.$route.query.orgId ? this.$route.query.orgId : "";
    if (this.productId || this.productCode || this.orgId) {
      //判断登陆状态

      //登陆状态
      if (this.userInfo) {
        if (this.productId) {
          //判断首页 首页传productId
          this.getProduct();
        } else {
          this.getShowProductInfo();
        }
      } else {
        //未登录
        this.getshowProductDesc();
      }
    }
    // 获取视频链接
    this.getVideoUrl()

    //政策假数据
    // this.zcList = util.zcList;
    setTimeout(() => {
      // this.parameterData = util.parameterData;
      // this.photoList = util.photoList;
    }, 500);
  },
  methods: {
    handleReviewDetail(){
      this.pageLoadFlag = true;
      let data ={
        serviceCode:'getContractInfoByContractNo',
        contractNo: this.productInfo.contractNo
      }
      ctsApi(data).then(res=>{
        window.open(res.data.data)
        // this.openPDFOnline(res.data.data,'application/pdf')
        this.pageLoadFlag = false;
      })
    },
    openPDFOnline (base64, contentType){
      const arr = base64.split(',');
      const contentIndex = arr.length > 1 ? 1 : 0;
      const bstr = atob(arr[contentIndex]);
      let leng = bstr.length;
      let u8arr = new Uint8Array(leng);
      while(leng--){
        u8arr[leng] =  bstr.charCodeAt(leng)
      }
      const blob = new Blob([u8arr],{type:contentType});

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob );
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getRebateProduct(pricingGroup){
      const { orgCode, isFenXiao, agentMdmCode, modelSap, b2bName  } = this.productInfo;
      try {
        ctsApi({
          serviceCode: isFenXiao == "N" ? "agentDirectDealerReturnPolicy" : "displayReturnPolicy",
          bigContractNo: "",
          companyCode: orgCode,
          contractNo: "",
          distributorsCisCode: isFenXiao == "N" ? "" : this.$store.state.user.userInfo.account.account,
          dlMdmCode: isFenXiao == "N" ? this.$store.state.user.userInfo.customer.customerCode : agentMdmCode,
          model: modelSap || b2bName ,
          pricingGroup,
          tableRow: "",
          isActivityArea: false
        }).then(res=>{
          if(res && res.data && res.data.data && res.data.data.model){
            this.productInfo.isShowRebate = true;
            this.productInfo.returnAmount = res.data.data.returnAmount;
            this.productInfo.contractNo = res.data.data.contractNo;
            this.$forceUpdate();
          }
        })
      } catch (error) {
      }
    },
    // 点击下单跳转下单页面
    gotoOrder(type) {
      let data = {
        productId: this.productInfo.productCode,
        qty:this.chooseNum,
        orgId:this.productInfo.orgId,
        policyId:this.policyItem.id?this.policyItem.id:'',
        versions:this.policyItem.id?this.policyItem.id:'',
        purchaseType:this.productInfo.purchaseType,

      }
      let path = ''
      let data1  = {}
      if(type == 'FX') {
        path = '/confirm/channel'
       data1 =  {
          productId: this.productInfo.productCode,
          qty:this.chooseNum,
          orgId:this.productInfo.orgId,
          policyId:this.policyItem.id?this.policyItem.id:'',
          versions:this.policyItem.id?this.policyItem.id:'',
          supplierName:this.productInfo.orgName,
          pageUrl:'detailPro',
        }
      } else {
        path = '/confirm'
        data1 =  {
          productId: this.productInfo.productCode,
          qty:this.chooseNum,
          orgId:this.productInfo.orgId,
          policyId:this.policyItem.id?this.policyItem.id:'',
          versions:this.policyItem.id?this.policyItem.id:'',
          purchaseType:this.productInfo.purchaseType,
          pageUrl:'detailPro',
          supplierName:this.productInfo.orgName,
          canChange:this.productInfo.purchaseType!==3?'N':'Y'
        }
      }
      this.$router.push({
        path:path,
        query:data1
      })

    },
    //政策显示隐藏
    isZCViewFun() {
      this.isZCView = !this.isZCView;
      if (!this.isZCView) {
        //隐藏状态
        $(".zc-box .ant-radio-group").scrollTop(0);
      }
    },
    //政策单选
   async onChange(e) {
      this.zcShowFun(true);
      this.policyItem = this.zcList[e.target.value];
      let params = {
        productId: this.productInfo.productCode,
        orgId: this.productInfo.orgId,
        orgCode: this.productInfo.orgCode,
        versionId:this.policyItem.id?this.policyItem.id:'',
      }
      getCust(params).then(res => {
        this.priceObj.fixedDiscount = Number(res.data.fixedDiscount)
        this.$forceUpdate()
        let fixedDiscount = this.priceObj.fixedDiscount
          ? this.priceObj.fixedDiscount
          : 0;
        this.afterPrice = (
          this.zcList[e.target.value].price *
          (100 - fixedDiscount) *
          0.01
        ).toFixed(2);
       }).catch(error => {
        console.log(error)
      })
    },
    //政策显示隐藏
    zcShowFun(isShow) {
      if (isShow) {
        this.zcCls = "fadeInUp";
      } else {
        this.zcCls = "fadeOut";
        //清空已选项
        this.policyItem = "";

        //数量置为1
        this.chooseNum = 1;
        // this.afterPrice = this.priceObj.price;
        //折扣价
        if (this.priceObj.fixedDiscount && this.priceObj.fixedDiscount > 0) {
          this.afterPrice = (
            this.priceObj.price *
            (100 - this.priceObj.fixedDiscount) *
            0.01
          )
            .toFixed(2)
            .toString();
        } else {
          this.afterPrice = 0;
        }
        setTimeout(() => {
          this.zcValue = "";
        }, 100);
      }
    },
    //获取购买数量
    getNumber(val) {
      // this.chooseNum = Number(val);
    },
    navChange(navIdx) {
      this.navActiveIdx = navIdx;
    },
    //判断详情通栏是否吸附
    handleScroll() {
      if ($("#boxFixedRefer").offset() && $("#boxFixedRefer").offset().top) {
        let scrollTop =
          $("#boxFixedRefer").offset().top - $(window).scrollTop();
        if (scrollTop <= 0) {
          this.isFixed = true;
        } else {
          this.isFixed = false;
        }
      }
    },
    //组装数据
    setData(res) {
      let filedMapArr = [];
      let imgArr = [];
      this.detailInfo = res.data;
      this.productInfo = res.data.product;
      this.zcList = res.data.versionList ? res.data.versionList : [];

      for (let item in res.data.pictures) {
        let dataItem = {
          imgUrl: res.data.product.materialGroup +
          "/" +
          res.data.product.productCode +
          "/650-650/" +
          res.data.pictures[item].pictureName,
          defaultImgUrl: '/assets/new/images/product/' +res.data.product.materialGroup + '.jpg'
        }
        imgArr.push(
          dataItem
        );
      }
      this.photoList = imgArr;
      // this.filedMap = res.data.filedMap;
      if (res.data.filedMap) {
        for (let item in res.data.filedMap) {
          let itemObj = {};
          itemObj.name = item;
          itemObj.infoList = res.data.filedMap[item];
          filedMapArr.push(itemObj);
          // filedMapArr.push(infoList);
        }
      }

      this.filedMap = filedMapArr;
      if (this.productInfo && this.userInfo) {
        if (this.productInfo.isFenXiao == "Y") {
          let paramDmsPrice = {
            orgId: this.productInfo.orgId,
            productId: this.productInfo.productCode
          };
          let paramDmsStock = {
            productCodes: [this.productInfo.productCode],
            supplierCode: this.productInfo.agentCisCode
          };
          //无orgId 不查询价格
          if(this.productInfo.orgId){
            getChannelPrice(paramDmsPrice)
              .then(pricesRes => {
                // if (pricesRes && pricesRes.data.code == 0) {
                  //开票价
                  this.priceObj = pricesRes.data.list[0]
                  this.$set(
                    this.priceObj,
                    "price",
                    pricesRes.data.list[0].standardPrice
                      ? pricesRes.data.list[0].standardPrice.toFixed(2).toString()
                      : null
                  );
                // }
              })
              .catch(error => {
                console.log(error);
              });
          }
          this.getRebateProduct("");
          if(this.productInfo.agentCisCode){
             // 根据产品列表获取库存
              getChannelStock(paramDmsStock)
              .then(stocksRes => {
                // 源数据赋值库存
                // if (stocksRes.statusText == "OK") {
                  this.stockObj = JSON.parse(stocksRes.data).data[0];
                  
                  this.$forceUpdate();
                // }
              })
              .catch(error => {
                console.log(error);
              });
          }
         
        } else {
          let param = {
            code: this.productInfo.productCode,
            orgId: this.productInfo.orgId,
            orgCode: this.productInfo.orgCode
          };
          //无orgId 不查询价格及库存
          if(!this.productInfo.orgId){
            return
          }
          // 根据产品列表获取价格
          getPrices(param)
            .then(pricesRes => {
              this.priceObj = pricesRes.data[0];
              this.getRebateProduct(this.priceObj.pricingGroupCode);
              //折扣价
              if (
                this.priceObj.fixedDiscount &&
                this.priceObj.fixedDiscount > 0
              ) {
                this.afterPrice = (
                  this.priceObj.price *
                  (100 - this.priceObj.fixedDiscount) *
                  0.01
                )
                  .toFixed(2)
                  .toString();
              } else {
                this.afterPrice = 0;
              }

              //开票价
              // this.priceObj.price = this.priceObj.price.toFixed(2);
              this.$set(
                this.priceObj,
                "price",
                this.priceObj.price.toFixed(2).toString()
              );
            })
            .catch(error => {
              console.log(error);
            });
          // 根据产品列表获取库存
          getStocks(param)
            .then(stocksRes => {
              this.stockObj = stocksRes.data[0];
            })
            .catch(error => {
              console.log(error);
            });

          // 根据产品列表获取库存
          let paramDmsStock = {
            productCodes: [this.productInfo.productCode],
            supplierCode: ''
          };
          getChannelStockLIST(paramDmsStock)
            .then(stocksRes => {
              // 源数据赋值库存
              // if (stocksRes.statusText == "OK") {
              // this.stockObj = JSON.parse(stocksRes.data).data[0];
              if(stocksRes.data && JSON.parse(stocksRes.data).data.length > 0) {
                this.dmsKC = Number(JSON.parse(stocksRes.data).data[0].gicInvQty) + Number(JSON.parse(stocksRes.data).data[0].invQty)
              }
              this.$forceUpdate();
              // }
            })
            .catch(error => {
              console.log(error);
            });
        }
      }
    },
    //获取产品详情 (登陆状态)
    getShowProductInfo() {
      showProductInfo(this.productCode, this.orgId)
        .then(res => {
          if (res.status == 200) {
            this.setData(res);
            this.getProduct();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    //获取产品详情 (未登陆状态)
    getshowProductDesc() {
      showProductDesc(this.productCode ? this.productCode : this.productId)
        .then(res => {
          this.setData(res);
          this.getProduct();
        })
        .catch(error => {
          console.log(error);
        });
    },
    //首页跳转 获取产品详情 (登陆状态)
    getProduct() {
      let params = { productId: this.productId ? this.productId : this.productCode};
      product(params)
        .then(res => {
          if(this.productId){
            this.setData(res);

          }
          
          this.targetPage = res.data.targetPage;
          this.showInfo = true

          
        })
        .catch(error => {
          console.log(error);
        });
    },
    //加入购物车
    addToCart() {
      let params = {
        productId: this.productInfo.productCode,
        orgId: this.productInfo.orgId,
        num: this.chooseNum,
        policyId: this.policyItem.id //政策id
      };
      this.$store.dispatch("user/addToCart", params);
      // addToCart(params)
      //   .then(res => {
      //     console.log(res);
      //     if (res.data) {
      //       this.$message.success("加入购物车成功");
      //     } else {
      //       this.$message.warning("加入购物车失败");
      //     }
      //   })
      //   .catch(error => {
      //     console.log(error);
      //     this.$message.warning("加入购物车失败");
      //   });
    },
    //加入收藏
    addOftenProduct() {
      let params = {
        id: this.productInfo.productCode,
        orgId: this.productInfo.orgId
      };
      addOftenProduct(params)
        .then(res => {
          if (res.data == "Y") {
            this.often = "Y";
            this.$message.success("加入收藏成功");
          } else if (res.data == "N") {
            this.$message.info("您已收藏");
          }
        })
        .catch(error => {
          console.log(error);
          this.$message.warning("加入收藏失败");
        });
    },
    //取消收藏
    deleteOftenProduct() {
      let params = {
        id: this.productInfo.productCode,
        orgId: this.productInfo.orgId
      };
      deleteOftenProduct(params)
        .then(res => {
          if (res.data.status == "true") {
            this.often = "N";
            this.$message.success("取消收藏成功");
          } else {
            this.$message.info("取消收藏失败");
          }
        })
        .catch(error => {
          console.log(error);
          this.$message.warning("取消收藏失败");
        });
    },
    // 获取视频链接
    getVideoUrl(){
      findVideos(this.productCode).then((res)=>{
        console.log('获取视频链接res', res)
        if(res.data.code == 0 && res.data.data[0]){
          this.videoUrl = res.data.data[0]
        }
      }).catch((err)=>{
        console.log('获取视频链接报错', err)
      })
    }
  }
};
